<template>
    <div>
        <BasicModal
            :show="subscriptionEdit"
            @close="onSubscriptionClose"
        >
            <div>
                <PageHeader>Change Account Level</PageHeader>
                <EditSubscriptionForm
                    :onCancel="onSubscriptionClose"
                    :onSave="onSubscriptionSave"
                    :currentDetails="currentDetails"
                />
            </div>
        </BasicModal>
        <div class="max-w-7xl mx-auto sm:px-8 lg:px-12 pb-10 pt-2">
            <FormKit
                type="form"
                v-model="dirtyAccount"
                :actions="false"
                :config="{ validationVisibility: 'live' }"
                #default="context"
            >
                <FormSection
                    class="pt-4"
                    :grid="['grid', 'grid-cols-1']"
                >
                    <div class="ml-4 grid grid-cols-1">
                        <FormEdit
                            v-if="!editing"
                            :onEdit="onEdit"
                            :onSubscriptionOpen="onSubscriptionOpen"
                            outerClass="px-4"
                        />
                        <FormSubmitCancel
                            v-if="editing"
                            :onSave="onClickSave"
                            :onCancel="onClickCancel"
                            :onEdit="onEdit"
                            :allowSave="context.state.valid"
                            outerClass="px-4"
                        />
                    </div>
                </FormSection>
                <FormSection class="pt-4">
                    <template #title>Account Settings</template>
                    <template #description>
                        View and edit account information.
                    </template>
                    <template #default>
                        <FormKit
                            type="text"
                            label="* Institution"
                            name="name"
                            :disabled="!editing"
                            :innerClass="editing ? '' : 'border-none'"
                            validation="required:trim|length:1"
                            outerClass="px-4"
                        />
                        <div class="flex">
                            <div class="w-full">
                                <FormKit
                                    type="text"
                                    label="Account Level"
                                    name="level_name"
                                    :disabled="true"
                                    :innerClass="'border-none '"
                                    outerClass="pl-4 whitespace-nowrap"
                                />
                            </div>
                            <a
                                class="font-bold text-sm text-primary-600 ml-20 pl-9 absolute"
                                href="#"
                                @click="onSubscriptionOpen"
                            >
                                (Upgrade)
                            </a>
                        </div>
                        <div class="flex">
                            <div style="w-full">
                                <FormKit
                                    type="text"
                                    label="Expires"
                                    name="expiration"
                                    placeholder="No Expiration Date"
                                    :disabled="true"
                                    :innerClass="'border-none'"
                                    outerClass="pl-4"
                                />
                            </div>
                            <a
                                class="font-bold text-sm text-primary-600 ml-10 pl-8 absolute"
                                href="#"
                                @click="onSubscriptionOpen"
                            >
                                (Extend)
                            </a>
                        </div>
                    </template>
                </FormSection>
                <FormSection class="pt-4">
                    <FormKit
                        type="text"
                        label="Users"
                        name="level_users"
                        :disabled="true"
                        :innerClass="'border-none'"
                        outerClass="px-4"
                    />
                    <FormKit
                        type="text"
                        label="Locations"
                        name="level_datasets"
                        :disabled="true"
                        :innerClass="'border-none'"
                        outerClass="px-4"
                    />
                </FormSection>
                <FormSection class="pt-4">
                    <FormKit
                        type="text"
                        label="Main Contact Email"
                        name="email"
                        :disabled="!editing"
                        :innerClass="editing ? '' : 'border-none'"
                        validation="required:trim|*email"
                        outerClass="px-4"
                    />
                    <FormKit
                        type="text"
                        label="Billing Contact Email"
                        name="billingEmail"
                        placeholder="N/A"
                        :disabled="!editing"
                        :innerClass="editing ? '' : 'border-none'"
                        outerClass="px-4"
                        validation="required|*email"
                    />
                </FormSection>
                <FormSection class="pt-4">
                    <FormKit
                        v-if="editing"
                        type="select"
                        label="Upload Reminder Frequency"
                        name="reminder"
                        :options="reminderOptions"
                        outerClass="px-4"
                    />
                    <section
                        class="px-4"
                        v-else
                    >
                        <label class="block mb-1 font-bold text-sm">
                            Upload Reminder Frequency
                        </label>
                        {{ cleanAccount.reminder }}
                    </section>
                    <FormKit
                        v-if="editing"
                        type="select"
                        label="Time Zone"
                        name="timezone"
                        :options="timezoneOptions"
                        outerClass="px-4"
                        :validation="[
                            ['required'],
                            ['*+not', 'placeholder', ...legacyTimezones],
                        ]"
                        valdiation-name="Timezone"
                        validation-visibility="live"
                    />
                    <section
                        class="px-4"
                        v-else
                    >
                        <label class="block mb-1 font-bold text-sm">
                            Time Zone
                        </label>
                        {{ cleanAccount.timezone }}
                    </section>
                    <FormKit
                        v-if="editing"
                        type="select"
                        label="Temperature Scale"
                        name="tempScale"
                        :options="temperatureOptions"
                        outerClass="px-4"
                    />
                    <section
                        class="px-4 pb-12"
                        v-else
                    >
                        <label class="block mb-1 font-bold text-sm">
                            Temperature Scale
                        </label>
                        &#176;{{ cleanAccount.tempScale }}
                    </section>
                </FormSection>
                <HierarchyFormSection class="pt-12">
                    <template #title>Manage Hierarchy</template>
                    <template #description>
                        <p>
                            NARA uses consistent terminology and abbreviations
                            for choices in the four Location Hierarchy levels.
                            <span class="font-bold"
                                >Level 1- Building. Level 2-Administration</span
                            >
                            (AO-Archives Office. FRC-Records Center.
                            PLA-Presidential Library Archives. PLM-Presidential
                            Library Museum).
                            <span class="font-bold">Level 3-Space Type</span>
                            (ST-Storage. WR-Workroom. EX-Exhibition. RR-Research
                            Room).
                            <span class="font-bold"
                                >Level 4-Monitoring Site</span
                            >
                            (space name/number or a custom identifier).
                        </p>
                    </template>
                    <template #default>
                        <FormKit
                            type="text"
                            label="Level 1"
                            name="site"
                            :disabled="!editing"
                            :innerClass="editing ? '' : 'border-none'"
                            validation="required:trim|length:1"
                            outerClass="px-4"
                        />
                        <FormKit
                            type="text"
                            label="Level 2"
                            name="building"
                            :disabled="!editing"
                            :innerClass="editing ? '' : 'border-none'"
                            validation="required:trim|length:1"
                            outerClass="px-4"
                        />
                        <FormKit
                            type="text"
                            label="Level 3"
                            name="floor"
                            :disabled="!editing"
                            :innerClass="editing ? '' : 'border-none'"
                            validation="required:trim|length:1"
                            outerClass="px-4"
                        />
                        <FormKit
                            type="text"
                            label="Level 4"
                            name="room"
                            :disabled="!editing"
                            :innerClass="editing ? '' : 'border-none'"
                            validation="required:trim|length:1"
                            outerClass="px-4"
                        />
                    </template>
                </HierarchyFormSection>
            </FormKit>
        </div>
    </div>
</template>

<script>
    // <!-- API -->
    import { defineComponent, ref } from 'vue';

    // <!-- COMPONENTS -->
    import FormSection from '@/components/forms/partials/FormSection.vue';
    import HierarchyFormSection from '@/components/forms/sections/HierarchyFormSection.vue';
    import FormSubmitCancel from '@/components/FormSubmitCancel.vue';
    import FormEdit from '@/components/FormEdit.vue';
    import BasicModal from '@components/BasicModal.vue';
    import PageHeader from '@components/PageHeader.vue';
    import EditSubscriptionForm from '~AccountSettings/components/EditSubscriptionForm.vue';

    // <!-- COMPOSABLES -->
    import {
        useTimezoneOptions,
        getLegacyTimezones,
    } from '~DataManager/hooks/useTimezoneOptions';
    import { useTemperatureOptions } from '~DataManager/hooks/useTemperatureOptions';
    import { useReminderOptions } from '~DataManager/hooks/useReminderOptions';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'AccountForm',
        props: {
            onCancel: Function,
            onSave: Function,
            onEdit: Function,
            editing: Boolean,
            cleanAccount: Object,
            onSubscriptionOpen: Function,
            onSubscriptionClose: Function,
            onSubscriptionSave: Function,
            subscriptionEdit: Boolean,
            currentDetails: Object,
        },
        components: {
            FormSection,
            HierarchyFormSection,
            FormEdit,
            FormSubmitCancel,
            BasicModal,
            PageHeader,
            EditSubscriptionForm,
        },
        setup(props, context) {
            let isLoading = ref(false);
            const dirtyAccount = ref({ ...props.cleanAccount });

            const onClickSave = () => {
                props.onSave(dirtyAccount);
            };
            const onClickCancel = () => {
                props.onCancel();
            };

            /* Data */
            const timezoneOptions = useTimezoneOptions();
            const legacyTimezones = getLegacyTimezones();
            const reminderOptions = useReminderOptions();
            const temperatureOptions = useTemperatureOptions();

            return {
                dirtyAccount,
                timezoneOptions,
                legacyTimezones,
                reminderOptions,
                temperatureOptions,
                isLoading,
                onClickCancel,
                onClickSave,
            };
        },
    });
</script>

<style lang="scss">
    .disabled-px-0:disabled {
        padding-left: 0;
        padding-right: 0;
    }

    .bottom-form hr:first-of-type {
        display: none;
    }
</style>
