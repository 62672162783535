// <!-- UTILITIES -->
import { isString, isPrimitive } from '@/utils/typeof';

// <!-- TYPES -->// TODO - Enhance with global types.
/** @template {String} [K=String] @template {String} [V=String] @typedef {Record<K,V>} IOptionRecord */
/** @template {String} [K=String] @template {String} [V=String] @typedef {Readonly<{ label: K, value: V }>} IOption */

// <!-- TYPE GUARD -->
/**
 * Type guard.
 * @template {String} [K=String]
 * @template {String} [V=String]
 * @template {[ key: K, value: V ]} [T=[ key: K, value: V ]]
 * @param {T} arg
 * @returns {arg is T} If `true` the type assert is valid.
 */
const isOptionTuple = (arg) => {
    if (
        arg !== null &&
        arg !== undefined &&
        Array.isArray(arg) &&
        arg.length === 2
    ) {
        const [key, value] = arg;
        return isString(key) && isString(value);
    }
    // Not exactly a two-element array.
    return false;
};
/**
 * Type guard.
 * @param {any} arg
 * @returns {arg is IOptionRecord} If `true` the type assert is valid.
 */
const isOptionRecord = (arg) => {
    if (arg !== null && arg !== undefined && !isPrimitive(arg)) {
        const keys = Object.keys(arg);
        if (keys.length > 0) {
            for (const key of keys) {
                if (isOptionTuple([key, arg[key]])) {
                    // Valid pair. Continue checking.
                    continue;
                }
                // Invalid pair.
                return false;
            }
            // Loop resolved without error. Valid record.
            return true;
        }
        // Empty record.
        return false;
    }
    // Primitive or nil.
    return false;
};
// <!-- EXPORTS -->
/**
 * @template {String} K
 * @template {String} [V=Readonly<IOptionRecord<K,String>>[K]]
 * @param {Readonly<IOptionRecord<K,V>>} record Record containing enumerated values.
 * @returns {Readonly<Array<{ label: keyof record, value: record[K] }>>}
 */
export const createOptions = (record) => {
    if (isOptionRecord(record)) {
        /** @type {Readonly<Array<IOption<K,record[K]>>>} */
        const options = Object.keys(record).map(
            /**
             * Cast each key into a proper option object.
             * @param {K} key
             * @returns {Readonly<{ label: K, value: record[key] }>}
             */
            (key) => Object.freeze({ label: key, value: record[key] })
        );
        // Return sealed array.
        return Object.freeze(options);
    }
    // Error: invalid values type.
    throw new TypeError('Values must be a valid enum props instance.');
};

// <!-- DEFAULT -->
export default createOptions;
