// <!-- UTILITIES -->
import isNil from 'lodash-es/isNil';

// <!-- TYPES -->
// TODO - Enhance with global types.
/** @typedef {import('@/utils/options').IOption} IOption */

/**
 * @implements {IOption}
 */
export class SimpleOption {
    /**
     * Clone existing {@link IOption} instance.
     * @param {Readonly<IOption>} option
     * @returns {IOption}
     */
    static clone = (option) => {
        return new SimpleOption(option);
    };

    /**
     * Create an individual {@link IOption} instance from explicit prop arguments.
     * @param {String} label
     * @param {String} [value] If none is provided, the label is reused as the value.
     */
    static create = (label, value = null) => {
        return new SimpleOption({ label, value: value ?? label });
    };

    /**
     * Create a simple option.
     * @param {Partial<Readonly<IOption>>} [props]
     */
    constructor(props = {}) {
        this.label = props.label ?? '';
        this.value = props.value ?? '';
    }

    /**
     * Is there a defined label?
     * @returns {Boolean}
     */
    get isLabelPresent() {
        return !isNil(this.label) && this.label !== '';
    }

    /**
     * Is there a defined value?
     * @returns {Boolean}
     */
    get isValuePresent() {
        return !isNil(this.value) && this.value !== '';
    }

    /**
     * Does the specified value match this instance's value.
     * @param {String} value
     * @returns {Boolean}
     */
    isValue(value) {
        return this.value === value;
    }
}

// <!-- DEFAULT -->
export default SimpleOption;
