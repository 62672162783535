<template>
    <BasicModal
        :show="open"
        :ignore="false"
        @close="$emit('close', $event)"
        hideCloseButton
    >
        <div class="sm:flex sm:items-start">
            <div
                class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-yellow-100 sm:mx-0 sm:h-10 sm:w-10"
            >
                <ExclamationIcon
                    class="h-6 w-6 text-yellow-600"
                    aria-hidden="true"
                />
            </div>
            <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                    {{ title }}
                </h3>
                <div class="mt-2">
                    <p class="text-sm text-gray-500">{{ confirmationText }}</p>
                </div>
            </div>
        </div>
        <div
            class="mt-5 sm:mt-3 flex flex-row-reverse flex-nowrap justify-center sm:justify-start sm:flex-wrap gap-x-4"
        >
            <ModalButton
                :theme="Theme.primary"
                @click="$emit('submit', $event)"
            >
                Submit
            </ModalButton>
            <ModalButton
                :theme="Theme.white"
                @click="$emit('cancel', $event)"
                ref="cancelButtonRef"
            >
                Cancel
            </ModalButton>
        </div>
    </BasicModal>
</template>
<script>
    // <!-- API -->
    import { defineComponent } from 'vue';

    // <!-- UTILITIES -->
    import { Size, Theme } from '@/utils/enums';

    // <!-- COMPONENTS -->
    import { ExclamationIcon } from '@heroicons/vue/outline';
    import BasicModal from '@/components/BasicModal.vue';
    import ModalButton from '@/components/modals/ModalButton.vue';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'ConfirmResetPasswordModal',
        components: {
            ExclamationIcon,
            BasicModal,
            ModalButton,
        },
        props: {
            open: Boolean,
            title: String,
            confirmationText: String,
        },
        emits: ['close', 'cancel', 'submit'],
        setup() {
            return {
                Size,
                Theme,
            };
        },
    });
</script>
