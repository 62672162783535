<template>
    <div
        v-if="!!sourceUser"
        :class="[isLoading ? 'animate-pulse' : '']"
    >
        <FormKit
            type="form"
            v-model="dirtyUser"
            #default="context"
            :actions="false"
            :config="{ validationVisibility: 'blur' }"
        >
            <FormKit
                type="text"
                name="username"
                label="* Username"
                validation="required"
                :disabled="!isEditing"
                @input="$emit('update:dirtyUser', { dirtyUser })"
            />
            <FormKit
                type="text"
                name="email"
                label="* Email"
                validation="email"
                :disabled="!isEditing"
                @input="$emit('update:dirtyUser', { dirtyUser })"
            />
            <FormKit
                type="radio"
                name="accessLevel"
                label="* Access Type"
                validation="required"
                :disabled="!isEditing"
                :options="{
                    admin: 'Admin',
                    normal: 'Data Manager',
                    readonly: 'Data Analyst',
                }"
                :config="{
                    // config override applies to all nested FormKit components
                    classes: {
                        outer: 'mb-5 mt-3 user-access-radio-list',
                        wrapper: 'flex mt-2',
                        label: 'mx-3',
                    },
                }"
                @input="$emit('update:dirtyUser', { dirtyUser })"
            />
            <p class="text-gray-400 text-sm">* indicates a required field</p>
            <FormSubmitCancel
                :allowSave="context.state.valid && !isLoading"
                :onSave="onClickSave"
                :onCancel="onClickCancel"
            />
        </FormKit>
    </div>
</template>

<script>
    // <!-- API -->
    import { defineComponent, ref, toRefs } from 'vue';

    // <!-- COMPONENTS -->
    import FormSubmitCancel from '@/components/FormSubmitCancel.vue';

    // <!-- TYPES -->

    /** @typedef {import('@/models/users/User').UserResource} UserResource */

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'UserFields',
        components: {
            FormSubmitCancel,
        },
        props: {
            sourceUser: {
                /** @type {V.PropType<Pick<UserResource, 'username' | 'email' | 'accessLevel'>>} */
                type: Object,
            },
            isEditing: {
                /** @type {V.PropType<Boolean>} */
                type: Boolean,
                default: false,
            },
            isLoading: {
                /** @type {V.PropType<Boolean>} */
                type: Boolean,
                default: false,
            },
            onCancel: {
                /** @type {V.PropType<() => void>} */
                // @ts-ignore
                type: Function,
                default: () => {
                    return false;
                },
            },
            onSave: {
                /** @type {V.PropType<(user: Pick<UserResource, 'username' | 'email' | 'accessLevel'>) => Promise<void>>} */
                // @ts-ignore
                type: Function,
                default: () => {
                    return false;
                },
            },
        },
        emits: ['update:dirtyUser'],
        setup(props, context) {
            // ==== PROPS ====
            /** @type {{ sourceUser: V.Ref<Pick<UserResource & { accessLevel?: string }, 'username' | 'email' | 'accessLevel'>> }} */
            const { sourceUser } = toRefs(props);

            // ==== STATE ====
            /** @type {V.Ref<Pick<UserResource & { accessLevel?: string }, 'username' | 'email' | 'accessLevel'>>} */
            const dirtyUser = ref(Object.assign({}, sourceUser.value));

            // ==== EVENTS ====

            /** Save the dirty user. */
            const onClickSave = async () => {
                await props.onSave(dirtyUser.value);
            };

            /** Clear the dirty user. */
            const onClickCancel = () => {
                props.onCancel();
                dirtyUser.value = null;
            };

            // ==== EXPOSE ====
            return { dirtyUser, onClickSave, onClickCancel };
        },
    });
</script>

<style lang="scss">
    .user-access-radio-list {
        li {
            display: inline-block;
        }
    }
</style>
