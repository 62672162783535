<template>
    <div class="pt-5">
        <div class="flex justify-end space-x-4">
            <ActionsMenu class="flex justify-end content-center mt-3 md:mt-0">
                <div class="py-1">
                    <MenuItem v-slot="{ active }">
                        <a
                            @click="onEdit"
                            :class="[
                                active
                                    ? 'bg-gray-100 text-gray-900'
                                    : 'text-gray-700',
                                'group flex items-center px-4 py-2 text-sm',
                            ]"
                        >
                            <PencilAltIcon
                                class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                aria-hidden="true"
                            />
                            Edit
                        </a>
                    </MenuItem>
                </div>
                <div class="py-1">
                    <MenuItem v-slot="{ active }">
                        <a
                            @click="onSubscriptionOpen"
                            :class="[
                                active
                                    ? 'bg-gray-100 text-gray-900'
                                    : 'text-gray-700',
                                'group flex items-center px-4 py-2 text-sm',
                            ]"
                        >
                            <CreditCardIcon
                                class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                aria-hidden="true"
                            />
                            Change or Extend Subscription
                        </a>
                    </MenuItem>
                </div>
            </ActionsMenu>
        </div>
    </div>
</template>

<script>
    // <!-- API -->
    import { defineComponent } from 'vue';

    // <!-- COMPONENTS -->
    import ActionsMenu from '@/components/ActionsMenu.vue';
    import { MenuItem } from '@headlessui/vue';
    import { PencilAltIcon, CreditCardIcon } from '@heroicons/vue/solid';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'FormEdit',
        props: {
            onEdit: Function,
            onSubscriptionOpen: Function,
        },
        components: {
            ActionsMenu,
            MenuItem,
            CreditCardIcon,
            PencilAltIcon,
        },
    });
</script>
