// <!-- UTILITIES -->
import isNil from 'lodash-es/isNil';

// <!-- TYPES -->// TODO - Enhance with global types.
/** @typedef {import('@/utils/options').IOption} IOption */
/** @typedef {import('@/utils/options').IDropdownOption} IDropdownOption */
/** @typedef {import('@/utils/options').IOptionSelected} IOptionSelected */

/**
 * Default selected action, notifies user no action has been assigned.
 * @param {IOption} target
 */
const DefaultSelectedAction = (target) => {
    if (!isNil(target)) {
        console.log(`[select::option] =>`, target);
    }
    console.warn(`No selected action assigned.`);
};

/**
 * @implements {IDropdownOption}
 */
export class DropdownOption {
    /**
     * Clone existing {@link IOption} instance.
     * @param {Readonly<IDropdownOption>} option
     * @returns {IDropdownOption}
     */
    static clone = (option) => {
        return new DropdownOption(option);
    };

    /**
     * Create an individual {@link IOption} instance from explicit prop arguments.
     * @param {String} label
     * @param {String} [value] If none is provided, the label is reused as the value.
     * @param {IOptionSelected} [onSelected] Action to invoke once selected.
     */
    static create = (
        label,
        value = null,
        onSelected = DefaultSelectedAction
    ) => {
        return new DropdownOption({ label, value: value ?? label, onSelected });
    };

    /**
     * Create a simple option.
     * @param {Partial<Readonly<IDropdownOption>>} [props]
     */
    constructor(props = {}) {
        this.label = props.label ?? '';
        this.value = props.value ?? '';
        this.onSelected = props.onSelected ?? DefaultSelectedAction;
    }

    /** Is there a defined label?*/
    get isLabelPresent() {
        return !isNil(this.label) && this.label !== '';
    }

    /** Is there a defined value? */
    get isValuePresent() {
        return !isNil(this.value) && this.value !== '';
    }

    /** Is there a defined {@link onSelected} callback? */
    get isCallbackDefined() {
        return !isNil(this.onSelected);
    }

    /**
     * Does the specified value match this instance's value.
     * @param {String} value
     * @returns {Boolean}
     */
    isValue(value) {
        return this.value === value;
    }
}

// <!-- DEFAULT -->
export default DropdownOption;
