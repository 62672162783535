// <!-- UTILITIES -->
import isNil from 'lodash-es/isNil';

// <!-- TYPES -->// TODO - Enhance with global types.
/** @typedef {import('@/utils/options').ISelectOption} ISelectOption */

/**
 * @implements {ISelectOption}
 */
export class SelectOption {
    /**
     * Clone existing {@link IOption} instance.
     * @param {Readonly<ISelectOption>} option
     * @returns {ISelectOption}
     */
    static clone = (option) => {
        return new SelectOption(option);
    };

    /**
     * Create an individual {@link IOption} instance from explicit prop arguments.
     * @param {String} label
     * @param {String} [value] If none is provided, the label is reused as the value.
     * @param {Boolean} [disabled] Is the option disabled?
     * @param {Boolean} [selected] Is the option preselected?
     */
    static create = (
        label,
        value = null,
        disabled = false,
        selected = false
    ) => {
        return new SelectOption({
            label,
            value: value ?? label,
            attrs: { disabled, selected },
        });
    };

    /**
     * Create a simple option.
     * @param {Partial<Readonly<ISelectOption>>} [props]
     */
    constructor(props = {}) {
        this.label = props.label ?? '';
        this.value = props.value ?? '';
        this.attrs = props.attrs ?? {};
    }

    /**
     * Is there a defined label?
     * @returns {Boolean}
     */
    get isLabelPresent() {
        return !isNil(this.label) && this.label !== '';
    }

    /**
     * Is there a defined value?
     * @returns {Boolean}
     */
    get isValuePresent() {
        return !isNil(this.value) && this.value !== '';
    }

    /**
     * Is the disabled flag set as `true`?
     * @returns {Boolean}
     */
    get isDisabled() {
        return (
            !isNil(this.attrs) &&
            !isNil(this.attrs.disabled) &&
            this.attrs.disabled === true
        );
    }

    /**
     * Is the preselected flag set as `true`?
     * @returns {Boolean}
     */
    get isPreselected() {
        return (
            !isNil(this.attrs) &&
            !isNil(this.attrs.selected) &&
            this.attrs.selected === true
        );
    }

    /**
     * Does the specified value match this instance's value.
     * @param {String} value
     * @returns {Boolean}
     */
    isValue(value) {
        return this.value === value;
    }
}

// <!-- DEFAULT -->
export default SelectOption;
