<template>
    <SettingsContainer>
        <template #mainContent>
            <PageWrapper>
                <LoadingWrapper :isLoading="isLoading">
                    <AccountForm
                        :cleanAccount="accountDetails"
                        :editing="editing"
                        :onCancel="onCancel"
                        :onSave="onSave"
                        :onEdit="onEdit"
                        :subscriptionEdit="subscriptionEdit"
                        :onSubscriptionOpen="onSubscriptionOpen"
                        :onSubscriptionClose="onSubscriptionClose"
                        :onSubscriptionSave="onSubscriptionSave"
                        :currentDetails="accountDetails"
                    />
                </LoadingWrapper>
            </PageWrapper>
        </template>
    </SettingsContainer>
</template>

<script>
    // <!-- API -->
    import {
        defineComponent,
        ref,
        onMounted,
        onActivated,
        onDeactivated,
    } from 'vue';
    import { useStore } from 'vuex';
    import accounts from '@/api/accounts';
    import locations from '@/api/accounts/locations';

    // <!-- COMPONENTS -->
    import LoadingWrapper from '@/components/LoadingWrapper.vue';
    import PageWrapper from '@components/PageWrapper.vue';
    import SettingsContainer from '~AccountSettings/components/wrappers/SettingsContainer.vue';
    import AccountForm from '~AccountSettings/components/AccountForm.vue';

    // <!-- UTILITIES -->
    import isNil from 'lodash-es/isNil';
    import { formatISO } from 'date-fns';
    import { DateTimeISO } from '@/utils/datetime';

    // <!-- TYPES -->

    /** @typedef {import('@/models/accounts/Account').AccountResource} AccountResource */
    /** @typedef {import('@/models/locations/Location').LocationResource} LocationResource */

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'AccountInformation',
        components: {
            LoadingWrapper,
            AccountForm,
            SettingsContainer,
            PageWrapper,
        },
        setup() {
            // PLUGINS
            const store = useStore();

            /* Refs */

            /** @type {V.Ref<Partial<AccountResource & { level_name: String, level_users: any, level_datasets: any, site: any, building: any, floor: any, room: any}>>} */
            const accountDetails = ref({});
            const accountLocations = ref([]);
            /** @type {V.Ref<Partial<AccountResource & { level_name: String, level_users: any, level_datasets: any, site: any, building: any, floor: any, room: any}>>} */
            const cleanAccountDetails = ref({});
            const isLoading = ref(false);
            let editing = ref(false);
            let subscriptionEdit = ref(false);

            /** @return {AccountResource} */
            const getCurrentAccount = () =>
                Object.assign({}, store.state.accounts.account);

            /* Functions */
            const useExpiredDateFormat = (account) => {
                const expiration = account?.expiration ?? null;
                if (!isNil(expiration)) {
                    const date = DateTimeISO.parse(expiration);
                    return formatISO(date, {
                        format: 'basic',
                        representation: 'complete',
                    });
                }
                // No expiration date? Send empty string.
                return '';
            };

            /**
             *
             * @param {Pick<AccountResource, 'billingEmail'>} account
             */
            const handleBillingEmail = (account) => {
                return !!account?.billingEmail &&
                    account.billingEmail.length > 0
                    ? account.billingEmail
                    : '';
            };

            /**
             *
             * @param {Pick<AccountResource, 'level' | 'usersCount'>} account
             */
            const handleUsers = ({ level, usersCount }) => {
                if (level.id === 8) {
                    return usersCount + '/Unlimited';
                } else if (level.id === 7) {
                    return usersCount + '/20';
                } else if (level.id === 9) {
                    return usersCount + '/10';
                } else if (level.id === 6) {
                    return usersCount + '/5';
                } else if (level.id === 5) {
                    return usersCount + '/1';
                } else {
                    return 'N/A';
                }
            };

            /**
             * @param {Pick<AccountResource, 'level'>} account
             * @param {LocationResource[]} accountLocations
             */
            const handleLocations = ({ level }, accountLocations) => {
                const locations = accountLocations.length
                    ? accountLocations.length
                    : 0;
                if (level.id === 8) {
                    return locations + '/Unlimited';
                } else if (level.id === 7) {
                    return locations + '/50';
                } else if (level.id === 9) {
                    return locations + '/25';
                } else if (level.id === 6) {
                    return locations + '/10';
                } else if (level.id === 5) {
                    return locations + '/3';
                } else {
                    return 'N/A';
                }
            };

            const handleReminder = ({ reminder }) => {
                if (reminder === 'never') {
                    return 'Not Now';
                } else if (reminder === 'biweekly') {
                    return 'Semi-monthly';
                } else {
                    return reminder;
                }
            };

            const convertTemp = (temp) => {
                if (temp == 'placeholder') {
                    return cleanAccountDetails.value.tempScale;
                } else {
                    return temp;
                }
            };

            const convertReminder = (reminder) => {
                if (reminder == 'Not Now') {
                    return 'never';
                } else if (reminder == 'Semi-monthly') {
                    return 'biweekly';
                } else {
                    return reminder;
                }
            };

            /**
             * @param {AccountResource} account
             * @param {LocationResource[]} locations
             */
            const setAccountDetails = (account, locations) => {
                accountDetails.value = account;
                accountLocations.value = locations;
                accountDetails.value.expiration = useExpiredDateFormat(
                    accountDetails.value
                );

                accountDetails.value.billingEmail = handleBillingEmail(account);

                // Level
                accountDetails.value.level_name = account.level.name;

                accountDetails.value.level_users = handleUsers(account);

                accountDetails.value.level_datasets = handleLocations(
                    account,
                    locations
                );

                accountDetails.value.reminder = handleReminder(account);

                // Manage Hierarchy
                accountDetails.value.site = account.level[0];
                accountDetails.value.building = account.level[1];
                accountDetails.value.floor = account.level[2];
                accountDetails.value.room = account.level[3];
            };

            const getAccountDetails = async (id) => {
                isLoading.value = true;
                const account = await accounts.fetchAccountById({ id });
                store.commit('setCurrentAccount', account);
                const hasLocations =
                    !!accountLocations.value &&
                    accountLocations.value.length > 0;
                const cachedLocations = hasLocations
                    ? accountLocations.value
                    : await locations.fetchLocations(account);
                setAccountDetails(account, cachedLocations);
                cleanAccountDetails.value = account;
                isLoading.value = false;
            };

            const onSubscriptionOpen = () => {
                subscriptionEdit.value = true;
            };
            const onSubscriptionClose = () => {
                subscriptionEdit.value = false;
            };
            /* TODO: Add save functionality for subscription */
            const onSubscriptionSave = () => {
                subscriptionEdit.value = false;
            };
            const onEdit = () => {
                editing.value = true;
            };
            const onCancel = async () => {
                const account = getCurrentAccount();
                getAccountDetails(account.id);
                editing.value = false;
            };

            const onSave = async (dirtyAccount) => {
                const payload = {
                    name: dirtyAccount.value.name,
                    email: dirtyAccount.value.email,
                    billing_email: dirtyAccount.value.billingEmail,
                    reminder: convertReminder(dirtyAccount.value.reminder),
                    timezone: dirtyAccount.value.timezone,
                    temp_scale: convertTemp(dirtyAccount.value.tempScale),
                    level: [
                        dirtyAccount.value.site,
                        dirtyAccount.value.building,
                        dirtyAccount.value.floor,
                        dirtyAccount.value.room,
                    ],
                    city: dirtyAccount.value.city,
                    state: dirtyAccount.value.state,
                    country: dirtyAccount.value.country,
                    account_tree_level: dirtyAccount.value?.account_tree_level,
                };
                isLoading.value = true;

                const account = getCurrentAccount();
                try {
                    const response = await accounts.updateAccountById(
                        account,
                        payload
                    );
                    getAccountDetails(response.account.id);
                } catch (err) {
                    console.error('Error updating account');
                    console.error(err);
                    console.error(err.response.data);
                    getAccountDetails(account.id);
                }
                editing.value = false;
            };

            /* Setup */

            onMounted(async () => {
                const account = getCurrentAccount();
                await getAccountDetails(account.id);
            });
            onActivated(async () => {
                const account = getCurrentAccount();
                await getAccountDetails(account.id);
            });
            onDeactivated(() => {
                editing.value = false;
                subscriptionEdit.value = false;
            });
            return {
                getAccountDetails,
                accountDetails,
                isLoading,
                cleanAccountDetails,
                editing,
                onEdit,
                onCancel,
                onSave,
                onSubscriptionOpen,
                onSubscriptionClose,
                onSubscriptionSave,
                subscriptionEdit,
            };
        },
    });
</script>
