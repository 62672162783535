<template>
    <FormKit
        type="form"
        v-model="selectedValues"
        :actions="false"
    >
        <FormKit
            type="select"
            label="Account Level:"
            name="accountLevel"
            :options="selectedValues.account_level"
            validation="required"
            :value="selectedValues.account_level"
        />
        <FormKit
            type="select"
            label="Extend your subscription by:"
            name="extendSubscription"
            :options="selectedValues.extend_subscription"
            validation="required"
            :value="selectedValues.extend_subscription"
        />
        <FormSubmitCancel
            :allowSave="true"
            :onSave="onClickSave"
            :onCancel="onClickCancel"
        />
    </FormKit>
</template>

<script>
    // <!-- API -->
    import { defineComponent, reactive, ref } from 'vue';

    // <!-- COMPONENTS -->
    import FormSubmitCancel from '@/components/FormSubmitCancel.vue';

    // <!-- COMPOSABLES -->
    import { useAxios } from '@/plugins/axios';

    // <!-- CONSTANTS -->

    /** Configured axios instance. */
    const axios = () => useAxios();

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'SubscriptionFields',
        components: {
            FormSubmitCancel,
        },
        props: {
            editing: Boolean,
            onSave: Function,
            onCancel: Function,
            currentDetails: Object,
        },
        setup(props, context) {
            /**
             * Gets the accountLevel and extendSubscription from the v-model.
             * @type {{ accountLevel?: String, extendSubscription?: String, account_level: String[], extend_subscription: String[] }}
             */
            const selectedValues = reactive({
                account_level: [
                    'Basic',
                    'Basic Plus',
                    'Professional',
                    'Professional Plus',
                ],
                extend_subscription: ['1 year'],
            });

            const currentAccount = reactive({ ...props.currentDetails });

            switch (currentAccount.level_name) {
                case 'Professional Plus':
                    selectedValues.account_level = ['Professional Plus'];
                    break;
                case 'Professional':
                    selectedValues.account_level = [
                        'Professional',
                        'Professional Plus',
                    ];
                    break;
                case 'Basic Plus':
                    selectedValues.account_level = [
                        'Basic Plus',
                        'Professional',
                        'Professional Plus',
                    ];
                    break;
                default:
                    selectedValues.account_level = [
                        'Basic',
                        'Basic Plus',
                        'Professional',
                        'Professional Plus',
                    ];
                    break;
            }

            const onClickSave = async () => {
                let setAccountLevel = 5;
                switch (selectedValues.accountLevel) {
                    case 'Basic':
                        setAccountLevel = 6;
                        break;
                    case 'Basic Plus':
                        setAccountLevel = 9;
                        break;
                    case 'Professional':
                        setAccountLevel = 7;
                        break;
                    default:
                        setAccountLevel = 8;
                        break;
                }

                const response = await axios().post(
                    `/accounts/${currentAccount.id}/upgrade`,
                    {
                        account_level: setAccountLevel, // Valid 5,6,7,8,9
                        account_subscription_length: 1, // Valid 1+
                    }
                );

                // @ts-ignore
                window.location = `https://store.imagepermanenceinstitute.org/cart/add/e-${response.data.account.plan_code}_q${response.data.account.subscription_length}_z${response.data.account.id}?destination=cart`;

                props.onCancel();
            };
            const onClickCancel = () => {
                console.log('Canceling...');
                props.onCancel();
            };

            return {
                selectedValues,
                currentAccount,
                onClickSave,
                onClickCancel,
            };
        },
    });
</script>
