<template>
    <div class="pb-6">
        <SubscriptionFields
            :editing="true"
            :onCancel="onSubscriptionClose"
            :onSave="onSubscriptionSave"
            :currentDetails="currentDetails"
        />
    </div>
</template>

<script>
    // <!-- API -->
    import { defineComponent, ref } from 'vue';

    // <!-- COMPONENTS -->
    import SubscriptionFields from '~AccountSettings/components/SubscriptionFields.vue';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'EditSubscriptionForm',
        props: {
            onCancel: Function,
            onSave: Function,
            currentDetails: Object,
        },
        components: {
            SubscriptionFields,
        },
        setup(props, context) {
            /* Data */
            const errorInputClasses =
                'pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md ';

            const onSubscriptionClose = () => {
                props.onCancel();
            };

            const onSubscriptionSave = () => {
                props.onCancel();
            };

            return {
                // onAddSave,
                onSubscriptionClose,
                onSubscriptionSave,
                errorInputClasses,
            };
        },
    });
</script>
